<template>
  <div class="create-actions">
    <div class="btn-wrapper btninline">
        <btn 
          label="Afslut" 
          width="100%" 
          center 
          @click="$emit('end-click')"   
          :disabled="enabled" 
        />
        <btn 
          label="Nulstil"
          style="margin-left: 12px;"
          width="100%" 
          center 
          @click="$emit('reset-click')"   
          :disabled="!enabled" 
        />
    </div>
    <div class="btn-actions">
      <btn
        label="Gem som PDF"
        width="100%"
        icon="images/create-actions-download.svg"
        :disabled="!enabled"
        @click="$emit('download-click')"
      />
      <btn
        label="Send som PDF"
        width="100%"
        icon="images/create-actions-plane.svg"
        :disabled="!enabled"
        @click="$emit('send-click')"
      />
      <btn
        label="Print"
        width="100%"
        icon="images/create-actions-printer.svg"
        :disabled="!enabled"
        @click="$emit('print-click')"
      />
    </div>
  </div>
</template>

<script>
import Btn from "@/components/Btn";
//import Row from "@/components/Row";

export default {
  name: "CreateActions",
  components: { Btn },
  props: {
    enabled: { 
      type: Boolean, 
      default: false 
    },
  },
};
</script>

<style>
.create-actions {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #fff;
}
.btn-wrapper {
  max-width: 251px;
  margin: 0 auto;
}
.btn-actions {
  margin-top: 30px;
}
.btn-actions > .btn {
  max-width: 251px;
  margin-bottom: 10px;
}
.btninline{
  display: flex !important;
}

@media screen and (min-width: 768px) {
  .btn-actions {
    display: flex;
    justify-content: space-between;
  }
  .btn-actions > .btn {
    width: 31% !important;
  }
}
</style>
