<template>
  <div
    :class="{
      'form-field': true,
      [className]: true,
      valid: valid === true,
      invalid: valid === false,
    }"
  >
    <div class="form-field-input-wrapper">
      <input
        type="text"
        :placeholder="placeholder"
        :value="value"
        @input="onInput"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "FormField",
  props: {
    value: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    valid: {
      type: Boolean,
      default: null,
    }
  },
  methods: {
    onInput(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style>
.form-field {
  margin-bottom: 10px;
  width: 100%;
  position: relative;
}
.form-field input {
  width: 100%;
  background: none;
  border: 1px solid #fff;
  border-radius: 8px;
  color: #fff;
  padding: 14px 20px 14px 10px;
}
.form-field input::placeholder {
  color: #fff;
}
.form-field-input-wrapper {
  position: relative;
}
.form-field.valid > .form-field-input-wrapper::after {
  content: "";
  display: block;
  width: 26px;
  height: 24px;
  background: url("../assets/form-field-check.svg");
  position: absolute;
  top: -12px;
  right: -8px;
}
.form-field.invalid input {
  border-color: #df6138;
}
.form-field__check {
  position: absolute;
  right: -12px;
  top: -8px;
}
</style>
