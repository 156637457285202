<template>
  <table class="table-delivery-days" cell-spacing="0" cell-pa>
    <tr>
      <th>Tillægsydelser til standardlevering</th>
      <th>Mandag-fredag</th>
      <th>Lørdag</th>
    </tr>
    <!-- <tr 
      class="table-delivery-days__item"
      @click="$emit('toggleservice2', {'index':1, 'item':{'label': 'Indbæring - levering til et af kunden udpeget let tilgængeligt rum*','weekdays' :true ,'saturday':false}})"
    
    >
      <td>Indbæring - levering til et af kunden udpeget let tilgængeligt rum*</td>
      <td>
          <input 
            type="checkbox" 
            :disabled="disable_special" 
            @click="special_click($event,8)"
            class="checkbox-service"
          />
      </td>
      <td>
          
      </td>
    </tr> -->
    <tr
      class="table-delivery-days__item"
      v-for="(item, index) in items"
      :key="`table-delivery-days-row-${index}`"
      @click="$emit('toggleservice1', {'index':index, 'item':item, 'week': week})"
    >
      <td>{{ item.text }}</td>
      <td>
         <span v-if="item.forall && item.manfred && !item.defaultchecked">
            <input 
              type="checkbox" 
              :disabled="disable_special" 
              :id="'service'+index" 
              @click="special_click($event,index); week =true"
              class="checkbox-service"
            />  
          </span>
          <span v-if="!item.forall && item.manfred && item.defaultchecked">
            <input 
              type="checkbox" 
              :disabled="disable_weekday" 
              :id="'service'+index" 
              @click="weekday_click($event,index); week =true"
              class="checkbox-service"
              checked
            />  
          </span>
        <span v-if="item.manfred && !item.forall && !item.defaultchecked">
          <!-- <img
            v-show="index !== current"
            class="table-select__check"
            src="images/table-select-check.svg"
          />
          <img
            v-show="index === current"
            class="table-select__check--active"
            src="images/table-select-check--active.svg"
            
          /> -->
          <input 
            type="checkbox" 
            :disabled="disable_weekday" 
            :id="'service'+index" 
            @click="weekday_click($event,index); week =true"
            class="checkbox-service"
          />
        </span>
      </td>
      <td>
        <span v-if="item.lordag">
          <!-- <img
            v-show="index !== current"
            class="table-select__check"
            src="images/table-select-check.svg"
          />
          <img
            v-show="index === current"
            class="table-select__check--active"
            src="images/table-select-check--active.svg"
          /> -->
          <input 
            type="checkbox" 
            :disabled="disable_saturday" 
            :id="'servicesat'+index" 
            @click="saturday_click($event,index); week = false"
            class="checkbox-service"
          />
        </span>
      </td>
      <!-- <td class="table-select__select-cell">
        <img
          v-show="index !== current"
          class="table-select__check"
          src="images/table-select-check.svg"
        />
        <img
          v-show="index === current"
          class="table-select__check--active"
          src="images/table-select-check--active.svg"
        />
      </td> -->
    </tr>
    <Modal
      v-show="isModalVisible"
      @close="closeModal"
      @submitTime="submitTime"
    />
  </table>
</template>

<script>
import Modal from '@/components/Modal.vue';
export default {
  name: "TableDeliveryDays",
  components: {
    Modal,
  },
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    // Dummy data
    return {
      isModalVisible: false,
      disable_weekday : false,
      disable_saturday : false,
      disable_special : false
    }
  },
  methods:{
    weekday_click(event,index){
      //console.log('==---checkbox',event.target.checked);
      // console.log('====-----index',index);
      if(event.target.checked == true){
        if(index == 0 ){
          document.getElementById("service1").disabled = true;
          document.getElementById("service2").disabled = true;
          document.getElementById("service3").disabled = true;
          document.getElementById("service4").disabled = true;
          document.getElementById("service5").disabled = true;
          document.getElementById("service6").disabled = true;
          //document.getElementById("service7").disabled = true;
        }
        else if(index == 1){
          document.getElementById("service0").disabled = true;
          document.getElementById("service2").disabled = true;
          document.getElementById("service3").disabled = true;
          document.getElementById("service4").disabled = true;
          document.getElementById("service5").disabled = true;
          document.getElementById("service6").disabled = true;
          //document.getElementById("service7").disabled = true;
        }
        else if(index == 2){
          document.getElementById("service0").disabled = true;
          document.getElementById("service1").disabled = true;
          document.getElementById("service3").disabled = true;
          document.getElementById("service4").disabled = true;
          document.getElementById("service5").disabled = true;
          document.getElementById("service6").disabled = true;
          //document.getElementById("service7").disabled = true;
        }
        else if(index == 3){
          document.getElementById("service0").disabled = true;
          document.getElementById("service1").disabled = true;
          document.getElementById("service2").disabled = true;
          document.getElementById("service4").disabled = true;
          document.getElementById("service5").disabled = true;
          document.getElementById("service6").disabled = true;
          //document.getElementById("service7").disabled = true;
        }
        else if(index == 4){
          document.getElementById("service0").disabled = true;
          document.getElementById("service1").disabled = true;
          document.getElementById("service2").disabled = true;
          document.getElementById("service3").disabled = true;
          document.getElementById("service5").disabled = true;
          document.getElementById("service6").disabled = true;
          //document.getElementById("service7").disabled = true;
        }
        else if(index == 5){
          document.getElementById("service0").disabled = true;
          document.getElementById("service1").disabled = true;
          document.getElementById("service2").disabled = true;
          document.getElementById("service3").disabled = true;
          document.getElementById("service4").disabled = true;
          document.getElementById("service6").disabled = true;
          //document.getElementById("service7").disabled = true;

        }else if(index == 6){
          this.isModalVisible = true;
          document.getElementById("service0").disabled = true;
          document.getElementById("service1").disabled = true;
          document.getElementById("service2").disabled = true;
          document.getElementById("service3").disabled = true;
          document.getElementById("service4").disabled = true;
          document.getElementById("service5").disabled = true;
          //document.getElementById("service7").disabled = true;
        }else if(index == 7){
          document.getElementById("service0").disabled = true;
          document.getElementById("service1").disabled = true;
          document.getElementById("service2").disabled = true;
          document.getElementById("service3").disabled = true;
          document.getElementById("service4").disabled = true;
          document.getElementById("service5").disabled = true;
          document.getElementById("service6").disabled = true;
        }else{
          document.getElementById("service0").disabled = false;
          document.getElementById("service1").disabled = false;
          document.getElementById("service2").disabled = false;
          document.getElementById("service3").disabled = false;
          document.getElementById("service4").disabled = false;
          document.getElementById("service5").disabled = false;
          document.getElementById("service6").disabled = false;
          document.getElementById("service7").disabled = false;
        }
        this.disable_saturday = true;
        this.disable_special = false;
      }else{
        this.disable_saturday = false; 
        this.disable_special = false;
        document.getElementById("service0").disabled = false;
        document.getElementById("service1").disabled = false;
        document.getElementById("service2").disabled = false;
        document.getElementById("service3").disabled = false;
        document.getElementById("service4").disabled = false;
        document.getElementById("service5").disabled = false;
        document.getElementById("service6").disabled = false;
      }
    },
    saturday_click(event,index){
      // console.log('==---checkbox',event.target.checked);
      // console.log('====-----index',index);
      if(event.target.checked == true){
        if(index == 2 ){
          document.getElementById("servicesat3").disabled = true;
          document.getElementById("servicesat8").disabled = true;
        }
        else if(index == 3){
          document.getElementById("servicesat2").disabled = true;
          document.getElementById("servicesat8").disabled = true;
        }
        else if(index == 8){
          document.getElementById("servicesat2").disabled = true;
          document.getElementById("servicesat3").disabled = true;
        }else{
          document.getElementById("servicesat2").disabled = false;
          document.getElementById("servicesat3").disabled = false;
          document.getElementById("servicesat8").disabled = false;  
        }
        this.disable_weekday = true;
        this.disable_special = true;
      }else{
        document.getElementById("servicesat2").disabled = false;
        document.getElementById("servicesat3").disabled = false;
        document.getElementById("servicesat8").disabled = false;
        this.disable_weekday = false;
        this.disable_special = false;
      }
    },
    special_click(event){
      if(event.target.checked == true){
        this.disable_saturday = true;
        this.disable_special = false;
      }else{
        this.disable_saturday = false; 
        this.disable_special = false;
      }  
    },
    closeModal() {
      this.isModalVisible = false;
    },
    submitTime(event){
      this.$emit('toggleservice1', {'index':6, 'week': true, selectedtime:event});
      this.isModalVisible = false; 
    }
  }
};
</script>

<style>
.table-delivery-days {
  margin-top: 30px;
  margin-bottom: 30px;
  border: none;
  border-spacing: 0;
  width: 100%;
}
.table-delivery-days__item {
  cursor: pointer;
}
.table-delivery-days td {
  text-align: center;
}
.table-delivery-days td:first-child {
  text-align: left;
}

.table-delivery-days th {
  text-align: left;
  font-size: 14px;
}
.table-delivery-days td,
.table-delivery-days th {
  border-bottom: 1px solid #fff;
  padding: 5px 10px;
}
.table-delivery-days td {
  border-right: 1px solid #fff;
}
.table-delivery-days td:last-child {
  border-right: none;
}

.checkbox-service {
  height: 25px;
  width: 25px;
  
}

@media screen and (min-width: 768px) {
  .table-delivery-days td,
  .table-delivery-days th {
    padding: 20px;
  }
}
</style>
