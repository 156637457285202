<template>
  <div class="toggle-buttons">
    <div class="toggle-buttons__header">{{ header }}</div>
    <button
      v-for="(item, index) in items"
      :key="`toggle-button-${index}`"
      :class="{
        'toggle-button': true,
        'toggle-button--active': index === current,
      }"
      @click="buttonClick(index)"
    >
      {{ item.label }}
    </button>
  </div>
</template>

<script>
export default {
  name: "ToggleButtons",
  props: {
    header: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    buttonClick(index) {
      if (index !== this.current) {
        this.$emit("toggle", index);
      }
    },
  },
};
</script>

<style>
.toggle-buttons__header {
  font-weight: bold;
  margin-bottom: 14px;
}
.toggle-button {
  color: #fff;
  background-color: transparent;
  height: 42px;
  padding: 0 20px;
  border-radius: 8px;
  border: 1px solid #fff;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
}
.toggle-buttons  button{
  width: min-content !important;
}
.toggle-button--active {
  background-color: #f15726;
  border: 1px solid #f15726;
}
@media (hover: hover) {
  .toggle-button {
    transition: background-color 0.1s ease-out;
  }
  .toggle-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .toggle-button--active:hover {
    background-color: #f15726;
  }
}
</style>
