<template>
  <div class="create">
    <row>
      <div class="create-container">
        <!-- <ordernumber-header :number="orderNumber" /> -->
        <toggle-buttons
          :header="togglebuttons.header"
          :items="togglebuttons.items"
          :current="togglebuttons.current"
          @toggle="buttonToggled($event)"
        />

        <expand-panel
          :index="1"
          label="Leveringsadresse"
          :current="expandPanel.current"
          @toggle="expandPanelToggle" 
        >
          <address-form 
            @update="formUpdate" 
            ref="test"
            :submitted="submitted"
            @nextClick="nextClick"
          />
          <!-- <btn label="Næste" width="200px" right @click="nextClick" /> -->
        </expand-panel>

        <expand-panel
          :index="2"
          label="Forsendelsesoplysninger"
          :current="expandPanel.current"
        >
          <kolli-list :items="kolliList.items" @add-kolli="addKolli" class="for-next"/>
          <btn label="Næste" width="200px" right @click="nextClick" :disabled="false"/>
        </expand-panel>

        <expand-panel
          :index="3"
          label="Serviceydelser"
          :current="expandPanel.current"
        >
          <!-- <table-select
            :items="deliveryOptions.items"
            :current="deliveryOptions.current"
            @toggle="deliveryOptionsToggle($event)"
          /> -->
          <table-delivery-days 
            :items="deliveryDays.items"
            @toggleservice1="deliveryOptionsToggle1($event)"
            @toggleservice2="deliveryOptionsToggle2($event)"
          />
          <btn label="Næste" width="200px" right @click="nextClick" />
        </expand-panel>

        <!-- <expand-panel
          :index="2"
          label="Leveringsadresse"
          :current="expandPanel.current"
          
        >
          <address-form 
            @update="formUpdate" 
            ref="test"
            :submitted="submitted"
            @nextClick="nextClick"
          />
          <btn label="Næste" width="200px" right @click="nextClick" />
        </expand-panel> -->
        <!-- <expand-panel
          :index="1"
          label="Serviceydelser"
          :current="expandPanel.current"
          @toggle="expandPanelToggle"
        >
          <table-select
            :items="deliveryOptions.items"
            :current="deliveryOptions.current"
            @toggle="deliveryOptionsToggle($event)"
          />
          <btn label="Næste" width="200px" right @click="nextClick" />
          <table-delivery-days :items="deliveryDays.items" />
        </expand-panel> -->
        <expand-panel
          :index="4"
          label="Besked til chaufføren"
          :current="expandPanel.current"
        >
          <text-message
            :placeholder="driverMessage.placeholder"
            :max-length="driverMessage.maxLength"
            v-model="driverMessage.message"
          />
          <!-- <btn label="Næste" width="200px" right @click="nextClick" /> -->
        </expand-panel>
        <create-actions
          :enabled="enabled"
          @reset-click="reSet"
          @end-click="createEnd"
          @download-click="createDownload"
          @send-click="createSend"
          @print-click="createPrint"
        />
      </div>
    </row>
  </div>
</template>

<script>

import Row from "@/components/Row";
import Btn from "@/components/Btn";
//import OrdernumberHeader from "@/components/OrdernumberHeader";
import ToggleButtons from "@/components/ToggleButtons";
import ExpandPanel from "@/components/ExpandPanel";
import CreateActions from "@/components/CreateActions";
import AddressForm from "@/components/AddressForm";
//import TableSelect from "@/components/TableSelect";
import TableDeliveryDays from "@/components/TableDeliveryDays";
import TextMessage from "@/components/TextMessage";
import KolliList from "@/components/KolliList";
import axios from "axios";

export default {
  name: "Create",
  components: {
    Row,
    Btn,
    //OrdernumberHeader,
    ToggleButtons,
    ExpandPanel,
    CreateActions,
    AddressForm,
    //TableSelect,
    TableDeliveryDays,
    TextMessage,
    KolliList
  },
  data() {
    // Dummy data

    return {
      isModalVisible: false,
      orderNumber: "",
      togglebuttons: {
        header: "Vælg mellem levering eller returnering",
        items: [{ label: "Levering" }, { label: "Returnering" }],
        current: 0,
      },
      expandPanel: {
        current: 1,
        actionsEnabled: false,
      },
      deliveryOptions: {
        items: [
          { label: "Standardlevering (Første dør gadeplan)" },
          { label: "Op bæring -  levering frem til hoveddør på etage" },
          {
            label:
              "Ind bæring - levering  til et af kundens udpegede og let tilgængelige rum*",
          },
          { label: "Lørdagslevering" },
        ],
        current: 5,
      },
      deliveryDays: {
        items: [
          // {
          //   label:
          //     "Indbæring - levering til et af kunden udpeget let tilgængeligt rum*",
          //   weekdays: true,
          //   saturday: false,
          //   service: 3
          // },
          // {
          //   label: "Dags/erhvervslevering tidsrum kl. 08.00 - 16.00",
          //   weekdays: true,
          //   saturday: false,
          //   service: 6
          // },
          // {
          //   label: "Først pà dagen levering tidsrum kl. 08.00 - 14.00",
          //   weekdays: true,
          //   saturday: true,
          //   service: 11
          // },
          // {
          //   label: "Midt pä dagen levering tidsrum kl. 11.00 - 17.00",
          //   weekdays: true,
          //   saturday: true,
          //   service: 12
          // },
          // {
          //   label: "Sidst på dagen levering - tidsrum kl. 15.00 - 21.00",
          //   weekdays: true,
          //   saturday: false,
          //   service: 13
          // },
          // {
          //   label: "Aftenslevering - tidsrum kl. 17.00 - 21.00",
          //   weekdays: true,
          //   saturday: false,
          //   service: 1
          // },
          // {
          //   label:
          //     "Tidsbestemt levering - frit 4 timers interval i tidsrum kl. 08.00 - 21.00",
          //   weekdays: true,
          //   saturday: false,
          //   service: 4
          // },
          // {
          //   label: "Lørdagslevering",
          //   weekdays: false,
          //   saturday: true,
          //   service: 5
          // },
        ],
      },
      driverMessage: {
        placeholder: "Skriv besked (Maks. 100 karakterer)",
        maxLength: 100,
        message: "",
        earliestTime: "08"
      },
      kolliList: {
        items: [
          { packageWeight: "", packageVolume: "", packageDescription: "" },
          // { weight: "200", volume: "1", description: "Lorem ipsum" },
        ],
      },
      createResponse : {
        // shipmentID: "S2204011234510001",
        // shipmentStatus: "ok",
        // packages:[
        //   {
        //     packCount: "2",
        //     packLine: "1",
        //     packStatus: "ok",
        //     packTT: "P2204011234510001001",
        //     packVolume: "12",
        //     packWeight: "10"
        //   },
        //   {
        //     packCount: "2",
        //     packLine: "2",
        //     packStatus: "ok",
        //     packTT: "P2204011234510001002",
        //     packVolume: "10",
        //     packWeight: "12"
        //   }
        // ]
      },
      mytoken : "",
      enabled : false,
      submitted : false,
      createData:{
        orderNumber : "",
        orderType : "1",
        shipmentRelease : "Y",
        testOrder: "N",

        company : "",
        customerName : "",
        customerAddress : "",
        customerAddress2 : "",
        customerZip : "",
        customerCity : "",
        earliestDate: "",
        latestDate : "",
        earliestTime : "08:00:00",
        latestTime : "21:00:00",
        customerPhone : "",
        customerMail : "",

        service1 : "",
        service2 : "",
        bearin : "",
        deliveryPickupPoint : "0",

        messageDriver : "",
        
        packages : [
          // {
          //   packageLine : "",
          //   packageWeight : "",
          //   packageVolume : "",
          //   packageDescription : ""
          // }
        ]
      }
    };
  },
  created(){
    this.mytoken = localStorage.getItem("token");
    if(this.mytoken == null || this.mytoken == ""){
      this.loggedIn = false;
      this.$router.push({ name: 'Login'}); 
    }else{
      this.mytoken = localStorage.getItem("token");
      
      var config = {
        method: "get",
        url: "https://engine.bftlogistik.dk:7000/partner/v1/checktoken/"+this.mytoken
      };
      axios(config)
      .then((response) => {
        if(response.data.token.status == true){
          this.serviceList();
          if(localStorage.getItem("disableButton") == 200){
            this.enabled = true;
            this.createResponse = JSON.parse(localStorage.getItem("createResponse"));
          } 
        }
      })
      .catch(function (error) {
        console.log(error);
        if(error.response?.status == 404)
        {
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
          localStorage.removeItem("pageSize");
          localStorage.removeItem("currentPage");
          localStorage.setItem("loggedIn", false);
          location.reload();
        }
      });
    }
  },
  methods: {
   
    serviceList(){
      var config = {
          method: "get",
          url: "https://engine.bftlogistik.dk:7000/partner/v1/getservicetext"
        };
        axios(config)
        .then((response) => {
          
          //console.log(response.data);

          this.deliveryDays.items.push({
            text:response.data.serv1.text,
            manfred:response.data.serv1.manfred,
            lordag:response.data.serv1.lordag,
            defaultchecked:true
          });
          this.deliveryDays.items.push({
            text:response.data.serv2.text,
            manfred:response.data.serv2.manfred,
            lordag:response.data.serv2.lordag
          });
          this.deliveryDays.items.push({
            text:response.data.serv3.text,
            manfred:response.data.serv3.manfred,
            lordag:response.data.serv3.lordag
          });
          this.deliveryDays.items.push({
            text:response.data.serv4.text,
            manfred:response.data.serv4.manfred,
            lordag:response.data.serv4.lordag
          });
          this.deliveryDays.items.push({
            text:response.data.serv5.text,
            manfred:response.data.serv5.manfred,
            lordag:response.data.serv5.lordag
          });
          this.deliveryDays.items.push({
            text:response.data.serv6.text,
            manfred:response.data.serv6.manfred,
            lordag:response.data.serv6.lordag
          });
          this.deliveryDays.items.push({
            text:response.data.serv7.text,
            manfred:response.data.serv7.manfred,
            lordag:response.data.serv7.lordag
          });
          this.deliveryDays.items.push({
            text:response.data.serv8.text,
            manfred:response.data.serv8.manfred,
            lordag:response.data.serv8.lordag,
            forall:true
          });
          this.deliveryDays.items.push({
            text:response.data.serv9.text,
            manfred:response.data.serv9.manfred,
            lordag:response.data.serv9.lordag
          });

        })
        .catch(function (error) {
          console.log(error);
          if(error.response.status == 403)
          {
            localStorage.removeItem("token");
            window.location.reload();
          } 
        });
    },
    buttonToggled(index) {
      if(index == 0){
        console.log("delivery");
        this.createData.orderType = "1";
      }else{
        console.log("pickup");
        this.createData.orderType = "2";
      }
      this.togglebuttons.current = index;
    },
    expandPanelToggle(index) {
      this.expandPanel.current = index;
    },
    createEnd() {
      this.$refs.test.$v.$touch();
      if (this.$refs.test.$v.$invalid) {
        this.submitted = true;
        return;
      }
      else{
        console.log(this.$refs.test.$v.$invalid); 
      
        this.kolliList.items = this.kolliList.items.filter((f) => f.packageDescription !== '' && f.packageVolume !== '' && f.packageWeight !== '');

        this.kolliList.items.forEach((e, i) => {
          e.packageLine = (i+1).toString();
        });
        
        var packagedata = (JSON.parse(JSON.stringify(this.kolliList.items)));
        this.createData.messageDriver = this.driverMessage.message;
        this.createData.packages = (JSON.parse(JSON.stringify(packagedata)));
        //console.log(JSON.parse(JSON.stringify(this.createData.packages)));
        var createData = this.createData;
        //console.log(createData);
        // var shipmentStatus = "ok";
        // if(shipmentStatus == "ok"){
        //   //alert("your shipmentdata is saved.");
        //   this.enabled = true;
        //   localStorage.setItem('disableButton',200);
        // }else{
        //   alert("your shipmentdata not saved, please try again later.");
        // }
        
        var config = {
            method: "post",
            url: "https://engine.bftlogistik.dk:7000/partner/v2/createshipment/" +this.mytoken,
            headers: {
            "Content-Type": "application/json",
            },
            data: createData
        };
        axios(config)
        .then((response) => {
          //console.log(response);
            if(response.data.shipmentStatus == "ok"){
              this.enabled = true;
              localStorage.setItem('disableButton',200);
              this.createResponse = response.data;
              localStorage.setItem('createResponse', JSON.stringify(this.createResponse));
              //console.log(this.createResponse);
              //location.reload();
            }else{
              alert("your shipmentdata not saved, please try again later.");
            }
        })
        .catch(function (error) {
            console.log(error);
            alert("shipment not created.");
        });
      }
    },
    reSet(){
      location.reload();
    },
    createDownload() {
      this.$router.push({
        name: 'Label',
        params: { packageresponse: this.createResponse }
      });
      console.log("Download");
    },
    createSend() {
      this.$router.push({
        name: 'Label',
        params: { packageresponse: this.createResponse }
      });
      console.log("Send");
    },
    createPrint() {
      this.$router.push({ 
        name: 'Label',
        params: { packageresponse: this.createResponse }
      });
      console.log("Print");
    },
    formUpdate(content) {
      //console.log("Form update", content);
      this.createData.company = content.company;
      this.createData.orderNumber = content.orderNumber;
      this.createData.customerName = content.firstname+' '+content.lastname;
      this.createData.customerAddress = content.street;
      this.createData.customerZip = content.zip;
      this.createData.customerCity = content.city;
      this.createData.earliestDate = content.earliestDate;
      //this.createData.latestDate = content.latestDate;
      this.createData.customerPhone = content.phone;
      this.createData.customerMail = content.email;
    },
    nextClick() {
      this.expandPanel.current++;
    },
    // deliveryOptionsToggle2(event) {
    //   this.deliveryOptions.current = event.index;
    //   this.createData.service2 = event.index.toString();
    // },

    // deliveryOptionsToggle1(event) {
    //   this.deliveryOptions.current = event.index;

    //   if(event.index == 4){
    //     this.showTime = true;
    //   }else{
    //     this.showTime = false;
    //   }
    //   this.createData.service1 = event.index.toString();
    // },
    deliveryOptionsToggle2(event) {
      console.log('deliveryOptionsToggle2===>',event);
    },

    deliveryOptionsToggle1(event) {
      this.deliveryOptions.current = event.index;

      if(event.index == 0){
        this.createData.earliestTime = "08:00:00";
        this.createData.latestTime = "21:00:00";
      }
      else if(event.index == 1){
        this.createData.earliestTime = "08:00:00";
        this.createData.latestTime = "16:00:00";
      }
      else if(event.index == 2)
      {
        this.createData.earliestTime = "08:00:00";
        this.createData.latestTime = "14:00:00";  
      }
      else if(event.index == 3)
      {
        this.createData.earliestTime = "11:00:00";
        this.createData.latestTime = "17:00:00";  
      }
      else if(event.index == 4)
      {
        this.createData.earliestTime = "15:00:00";
        this.createData.latestTime = "21:00:00";  
      }
      else if(event.index == 5)
      {
        this.createData.earliestTime = "17:00:00";
        this.createData.latestTime = "21:00:00";  
      }
      else if(event.index == 6)
      {
        if(event.selectedtime){
          const slectedtime = event.selectedtime.split('-');
          this.createData.earliestTime = slectedtime[0]+':00';
          this.createData.latestTime = slectedtime[1]+':00';
        }else{
          this.createData.earliestTime = "08:00:00";
          this.createData.latestTime = "21:00:00";  
        }
        console.log(this.createData.earliestTime,this.createData.latestTime);
      }
      else if(event.index == 7)
      {
        this.createData.earliestTime = "08:00:00";
          this.createData.latestTime = "21:00:00"; 
      }
      else if(event.index == 8)
      {
        this.createData.earliestTime = "08:00:00";
        this.createData.latestTime = "17:00:00";  
      }

      if(event.week == true){
          if(event.index == 7){
            var bearin = event.index + 1;
            this.createData.bearin = bearin.toString();    
          }else{
            var service1 = event.index + 1;
            this.createData.service1 = service1.toString();  
          }
      }
      if(event.week == false){
        var service2 = event.index + 1;
        this.createData.service2 = service2.toString();  
      }
    },

    addKolli() {
      this.lineno =  this.kolliList.items.length+1;
      this.kolliList.items.push({
        //packageLine: lineno.toString(),
        packageWeight: "",
        packageVolume: "", 
        packageDescription: ""
      });
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>

<style>
.create-container {
  padding: 30px 0;
  max-width: 812px;
  margin: 0 auto;
}
.form-field__time{
  width: 18%;
  /* margin-left: 20px; */
}
.for-next{
  margin-bottom: 12px; 
}

</style>
