<template>
<div>
  <form @submit.prevent="nextClick">
  <div class="address-form">
    <form-field-group label="Firma">
      <form-field
        className="form-field__company"
        ref="company"
        placeholder="Firmanavn (ikke påkrævet)"
        v-model="formContent.company"
        
      />
      <!-- <div
          v-if="submitted && !$v.formContent.company.required"
          class="invalid-feedback"
      >
          company is required
      </div> -->
    </form-field-group>
    <form-field-group label="Ordrenummer">
      <div class="form-field form-field__firstname" :valid="formValid.orderNumber">
        <div class="form-field-input-wrapper">
          <input 
            type="text" 
            placeholder="Ordrenummer" 
            v-model="formContent.orderNumber"
            :class="{
                'is-invalid': submitted && $v.formContent.orderNumber.$error,
            }"
          >
          <div
              v-if="submitted && !$v.formContent.orderNumber.required"
              class="invalid-feedback"
          >
              orderNumber is required
          </div>
        </div>
      </div>
    </form-field-group>
    <form-field-group label="Navn">
      <!-- <form-field
        className="form-field__firstname"
        placeholder="Fornavn*"
        v-model="formContent.firstname"
        :valid="formValid.firstname"
        :class="{
            'is-invalid': submitted && $v.formContent.firstname.$error,
        }"
      />
      <div
          v-if="submitted && !$v.formContent.firstname.required"
          class="invalid-feedback"
      >
          firstname is required
      </div> -->
      <div class="form-field form-field__firstname" :valid="formValid.firstname">
        <div class="form-field-input-wrapper">
          <input 
            type="text" 
            placeholder="Fornavn" 
            v-model="formContent.firstname"
            :class="{
                'is-invalid': submitted && $v.formContent.firstname.$error,
            }"
          >
          <div
              v-if="submitted && !$v.formContent.firstname.required"
              class="invalid-feedback"
          >
              firstname is required
          </div>
        </div>
      </div>
      <!-- <form-field
        className="form-field__lastname"
        placeholder="Efternavn*"
        v-model="formContent.lastname"
        :valid="formValid.lastname"
        :class="{
            'is-invalid': submitted && $v.formContent.lastname.$error,
        }"
      />
      <div
          v-if="submitted && !$v.formContent.lastname.required"
          class="invalid-feedback"
      >
          lastname is required
      </div> -->
      <div class="form-field form-field__lastname" :valid="formValid.lastname">
        <div class="form-field-input-wrapper">
          <input 
            type="text" 
            placeholder="Efternavn" 
            v-model="formContent.lastname"
            :class="{
                'is-invalid': submitted && $v.formContent.lastname.$error,
            }"
          >
          <div
              v-if="submitted && !$v.formContent.lastname.required"
              class="invalid-feedback"
          >
              lastname is required
          </div>
        </div>
      </div>
    </form-field-group>
    <form-field-group label="Adresse">
      <!-- <form-field
        className="form-field__street"
        placeholder="Vejnavn*"
        v-model="formContent.street"
        :valid="formValid.street"
        :class="{
            'is-invalid': submitted && $v.formContent.street.$error,
        }"
      />
      <div
          v-if="submitted && !$v.formContent.street.required"
          class="invalid-feedback"
      >
          street is required
      </div> -->
      <div class="form-field form-field__street" :valid="formValid.street">
        <div class="form-field-input-wrapper">
          <input 
            type="text" 
            placeholder="Vejnavn" 
            v-model="formContent.street"
            :class="{
                'is-invalid': submitted && $v.formContent.street.$error,
            }"
          >
          <div
              v-if="submitted && !$v.formContent.street.required"
              class="invalid-feedback"
          >
              street is required
          </div>
        </div>
      </div>

      <!-- <form-field
        className="form-field__streetnumber"
        placeholder="Husnr.*"
        v-model="formContent.streetnumber"
        :valid="formValid.streetnumber"
        :class="{
            'is-invalid': submitted && $v.formContent.streetnumber.$error,
        }"
      />
      <div
          v-if="submitted && !$v.formContent.streetnumber.required"
          class="invalid-feedback"
      >
          streetnumber is required
      </div> -->
      <!-- <div class="form-field form-field__streetnumber" :valid="formValid.streetnumber">
        <div class="form-field-input-wrapper">
          <input 
            type="text" 
            placeholder="Husnr." 
            v-model="formContent.streetnumber"
            :class="{
                'is-invalid': submitted && $v.formContent.streetnumber.$error,
            }"
          >
          <div
              v-if="submitted && !$v.formContent.streetnumber.required"
              class="invalid-feedback"
          >
              streetnumber is required
          </div>
        </div>
      </div> -->
      <!-- <form-field
        className="form-field__floor"
        placeholder="Etage*"
        v-model="formContent.floor"
        :valid="formValid.floor"
        :class="{
            'is-invalid': submitted && $v.formContent.floor.$error,
        }"
      />
      <div
          v-if="submitted && !$v.formContent.floor.required"
          class="invalid-feedback"
      >
          floor is required
      </div> -->
      <!-- <div class="form-field form-field__floor" :valid="formValid.floor">
        <div class="form-field-input-wrapper">
          <input 
            type="text" 
            placeholder="Etage" 
            v-model="formContent.floor"
          >
        </div>
      </div> -->
      <!-- <form-field
        className="form-field__zip"
        placeholder="Postnummer*"
        v-model="formContent.zip"
        :valid="formValid.zip"
        :class="{
            'is-invalid': submitted && $v.formContent.zip.$error,
        }"
      />
      <div
          v-if="submitted && !$v.formContent.zip.required"
          class="invalid-feedback"
      >
          zip is required
      </div> -->
      <div class="form-field form-field__zip" :valid="formValid.zip">
        <div class="form-field-input-wrapper">
          <input 
            type="text" 
            placeholder="Postnummer" 
            v-model="formContent.zip"
            :class="{
                'is-invalid': submitted && $v.formContent.zip.$error,
            }"
          >
          <div
              v-if="submitted && !$v.formContent.zip.required"
              class="invalid-feedback"
          >
              zip is required
          </div>
        </div>
      </div>
      <!-- <form-field
        className="form-field__city"
        placeholder="By*"
        v-model="formContent.city"
        :valid="formValid.city"
        :class="{
            'is-invalid': submitted && $v.formContent.city.$error,
        }"
      />
      <div
          v-if="submitted && !$v.formContent.city.required"
          class="invalid-feedback"
      >
          city is required
      </div> -->
      <div class="form-field form-field__city" :valid="formValid.city">
        <div class="form-field-input-wrapper">
          <input 
            type="text" 
            placeholder="By" 
            v-model="formContent.city"
            :class="{
                'is-invalid': submitted && $v.formContent.city.$error,
            }"
          >
          <div
              v-if="submitted && !$v.formContent.city.required"
              class="invalid-feedback"
          >
              city is required
          </div>
        </div>
      </div>
    </form-field-group>

    <form-field-group label="leveringsdato">
     
      <div class="form-field form-field__date" :valid="formValid.earliestDate">
        <div class="form-field-input-wrapper">
          <!-- <label>tidligste dato</label> -->
          <input 
            type="date" 
            placeholder="tidligste dato*" 
            v-model="formContent.earliestDate"
            :class="{
                'is-invalid': submitted && $v.formContent.earliestDate.$error,
            }"
          >
          <div
              v-if="submitted && !$v.formContent.earliestDate.required"
              class="invalid-feedback"
          >
              earliestDate is required
          </div>
        </div>
      </div>
    </form-field-group>
    <!-- <form-field-group label="4 timer, begyndende fra kl." v-show="fieldview">
      <div class="form-field form-field__time" :valid="formValid.earliestTime">
        <div class="form-field-input-wrapper">
          <vue-timepicker  
            format="HH" 
            v-model="formContent.earliestTime" 
            value="18"
            hide-clear-button
          >
          </vue-timepicker>
          
        </div>
      </div>
    </form-field-group> -->
    <!-- <form-field-group label="seneste dato og tid">
      
      <div class="form-field form-field__date" :valid="formValid.latestDate">
        <div class="form-field-input-wrapper">
          <input type="date" placeholder="seneste dato*" v-model="formContent.latestDate">
        </div>
      </div>

      <div class="form-field form-field__time" :valid="formValid.latestTime">
        <div class="form-field-input-wrapper">
          <vue-timepicker  format="HH" v-model="formContent.latestTime"></vue-timepicker>
        </div>
      </div>
    </form-field-group> -->

    <form-field-group label="Kontaktoplysninger">
      <!-- <form-field
        className="form-field__email"
        placeholder="Mailadresse*"
        v-model="formContent.email"
        :valid="formValid.email"
        :class="{
            'is-invalid': submitted && $v.formContent.email.$error,
        }"
      />
      <div
          v-if="submitted && !$v.formContent.email.required"
          class="invalid-feedback"
      >
          email is required
      </div> -->
      <div class="form-field form-field__email" :valid="formValid.email" >
        <div class="form-field-input-wrapper">
          <input 
            type="text" 
            placeholder="Mailadresse" 
            v-model="formContent.email"
            :class="{
                'is-invalid': submitted && $v.formContent.email.$error,
            }"
          >
          <div
              v-if="submitted && !$v.formContent.email.required"
              class="invalid-feedback"
          >
              email is required
          </div>
        </div>
      </div>
      <!-- <form-field
        className="form-field__phone"
        placeholder="Telefonnummer*"
        v-model="formContent.phone"
        :valid="formValid.phone"
        :class="{
            'is-invalid': submitted && $v.formContent.phone.$error,
        }"
      />
      <div
          v-if="submitted && !$v.formContent.phone.required"
          class="invalid-feedback"
      >
          phone is required
      </div> -->
      <div class="form-field form-field__phone" :valid="formValid.phone">
        <div class="form-field-input-wrapper">
          <input 
            type="text" 
            placeholder="Telefonnummer" 
            v-model="formContent.phone"
            :class="{
                'is-invalid': submitted && $v.formContent.phone.$error,
            }"
          >
          <div
              v-if="submitted && !$v.formContent.phone.required"
              class="invalid-feedback"
          >
              phone is required
          </div>
        </div>
      </div>
    </form-field-group>
  </div>
  <btn type="submit" label="Næste" width="200px" right />
  </form>
</div>
</template>

<script>
import FormFieldGroup from "@/components/FormFieldGroup";
import FormField from "@/components/FormField";
//import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import { required } from "vuelidate/lib/validators";
import Btn from "@/components/Btn";

export default {
  name: "AddressForm",
  props:['submitted'],
  components: { 
    FormFieldGroup, 
    FormField,
    Btn,
    //VueTimepicker
  },
  data() {
    return {
      fieldview : false,
      formContent: {
        company: "",
        orderNumber: "",
        firstname: "",
        lastname: "",
        street: "",
        streetnumber: "",
        floor: "",
        zip: "",
        city: "",
        email: "",
        phone: "",
        earliestDate: "",
        //latestDate: "",
        //earliestTime: "08",
        //latestTime: ""
      },
      formValid: {
        company: true,
        orderNumber: true,
        firstname: true,
        lastname: true,
        street: true,
        //streetnumber: true,
        //floor: null,
        zip: null,
        city: null,
        email: false,
        phone: true,
        earliestDate: null,
        // latestDate: null,
        //earliestTime: null,
        // latestTime: null
      },
    };
  },
  validations: {
    formContent: {
        //company: { required },
        orderNumber: { required },
        firstname: { required },
        lastname: { required },
        street: { required },
        //streetnumber: { required },
        //floor: { required },
        zip: { required },
        city: { required },
        email: { required },
        phone: { required },
        earliestDate: { required },
        //earliestTime: { required },
      },          
  },
  watch: {
    formContent: {
      deep: true,
      handler() {
        this.$emit("update", this.formContent);
      }
    },
    // showTime: function() {
    //   this.fieldview = this.showTime;
    //   console.log(this.fieldview);
    // }
  },
  methods:{
    nextClick(){
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      else{
        this.submitted = false;
        this.$emit("nextClick");
      }
    }
  }
};
</script>

<style>
.form-field__street {
  width: 70%;
  padding-right: 5px;
}
.form-field__streetnumber {
  width: 30%;
  padding-left: 5px;
}
.form-field__zip {
  width: 30%;
  padding-right: 5px;
}
.form-field__city {
  width: 70%;
  padding-left: 5px;
}
.form-field{
  margin-right: 0 !important;
}

form{
  width: 100% !important;
  margin: 0 !important;
}


@media screen and (min-width: 768px) {
  .form-field__firstname {
    width: 50%;
    padding-right: 5px;
  }
  .form-field__lastname {
    width: 50%;
    padding-left: 5px;
  }
  .form-field__street {
    width: calc(35% - 5px);
    padding-right: 5px;
  }
  .form-field__streetnumber {
    width: 15%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .form-field__floor {
    width: 15%;
    padding-left: 5px;
    margin-right: 30%;
  }
  .form-field__zip {
    width: 15%;
  }
  .form-field__city {
    width: calc(35% - 10px);
  }
  .form-field__email {
    width: 50%;
    padding-right: 5px;
  }
  .form-field__phone {
    width: 30%;
    padding-left: 5px;
  }
  .form-field__date{
    width: calc(30% - 5px);
    padding-right: 5px;
  }
  .form-field__etime{
    width: 18%;
  }
  .form-field__time{
    width: 18%;
    /* margin-left: 20px; */
  }
  .vue__time-picker input.display-time{
    border: 1px solid #fff;
    width: 10em;
    height: 45px !important;
    padding: .3em .5em;
    font-size: 1em;
    color: white !important;
  }
  .invalid-feedback{
    color: red;
  }
}
</style>
