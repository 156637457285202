<template>
  <div class="kolli-list">
    <div class="kolli-list__bar">
      <div class="kolli-list__bar-item kolli-list__bar-item--number">
        Kolli nr.
      </div>
      <div class="kolli-list__bar-item kolli-list__bar-item--weight">Vægt (kg)</div>
      <div class="kolli-list__bar-item kolli-list__bar-item--volume">
        Volumen (m3) <img src="images/kolli-list-info.svg" @click="showCalculation"/>
      </div>
      <div class="kolli-list__bar-item kolli-list__bar-item--description">
        Pakkens indhold
      </div>
    </div>
    <div class="kolli-list__content">
      <div
        class="kolli-item-row"
        v-for="(item, index) in items"
        :key="`kolli-item-row-${index}`"
      >
        <div class="kolli-item kolli-item--number">{{ index + 1 }}</div>
        <div class="kolli-item kolli-item--weight">
          <form-field placeholder="Vægt i kg*" v-model="item.packageWeight" />
        </div>
        <div class="kolli-item kolli-item--volume">
          <form-field placeholder="Volumen i m3*" v-model="item.packageVolume" />
        </div>
        <div class="kolli-item kolli-item--description">
          <form-field
            placeholder="Skriv hvad pakken indeholder*"
            v-model="item.packageDescription"
          />
        </div>
      </div>
    </div>
    <div class="kolli-list__actions">
      <div class="kolli-list__btn" @click="$emit('add-kolli')">
        Tilføj kolli
        <img
          class="kolli-list__btn-icon"
          src="images/kolli-list-btn-icon.svg"
        />
      </div>
    </div>
    <Calculationmodal
      v-show="isModalVisible"
      @close="closeModal"
    />
  </div>
</template>

<script>
import FormField from "@/components/FormField";
import Calculationmodal from '@/components/CalculationModel.vue';
export default {
  name: "KolliList",
  components: { 
    FormField,
    Calculationmodal 
  },
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data(){
    return{
      isModalVisible: false,
    }
  },
  methods:{
    closeModal() {
      this.isModalVisible = false;
    },
    showCalculation(){
      this.isModalVisible = true;
    }
  }
  
};
</script>

<style>
/* .kolli-list {
} */
.kolli-list__bar {
  display: flex;
  font-weight: bold;
  padding-bottom: 20px;
}
.kolli-list__bar-item {
  width: calc(100% / 3);
}
.kolli-list__bar-item.kolli-list__bar-item--description {
  display: none;
}
.kolli-item-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.kolli-item.kolli-item--number {
  width: calc(100% / 3);
  padding-top: 12px;
}
.kolli-item.kolli-item--weight {
  width: calc(100% / 3 - 5px);
  margin-right: 5px;
}
.kolli-item.kolli-item--volume {
  width: calc(100% / 3 - 5px);
  margin-left: 5px;
}
.kolli-list__bar-item--volume img {
  vertical-align: middle;
  margin-top: -2px;
  margin-left: 3px;
  width: 21px;
  height: auto;
}
.kolli-item.kolli-item--description {
  width: 100%;
}
.kolli-list__btn {
  display: inline-block;
  position: relative;
  background-color: #56bae0;
  color: #fff;
  height: 42px;
  line-height: 42px;
  padding: 0 100px 0 20px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.1 ease;
}
.kolli-list__btn-icon {
  position: absolute;
  top: 3px;
  right: 10px;
}
.kolli-list__actions {
  text-align: right;
}

@media screen and (min-width: 768px) {
  .kolli-list__bar-item.kolli-list__bar-item--number {
    width: 16%;
  }
  .kolli-list__bar-item.kolli-list__bar-item--weight {
    width: 16%;
  }
  .kolli-list__bar-item.kolli-list__bar-item--volume {
    width: 20%;
  }
  .kolli-list__bar-item.kolli-list__bar-item--description {
    display: block;
    width: 52%;
  }

  .kolli-item.kolli-item--number {
    width: calc(16% - 10px);
    /* margin-right: 10px; */
  }
  .kolli-item.kolli-item--weight {
    width: calc(16% - 10px);
    margin-right: 10px;
  }
  .kolli-item.kolli-item--volume {
    width: calc(16% - 10px);
    margin-right: 10px;
    margin-left: 0;
  }
  .kolli-item.kolli-item--description {
    width: 52%;
  }
}
</style>
