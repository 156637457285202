<template>
  <textarea class="text-message" maxlength="100" @input="onInput" />
</template>

<script>
export default {
  name: "TextMessage",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  methods: {
    onInput(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style>
.text-message {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 20px;
  background: none;
  color: #fff;
  margin-bottom: 30px;
  width: 100%;
  height: 140px;
  outline: none;
}
.text-message::placeholder {
  color: #fff;
}
</style>
