<template>
  <div class="form-field-group">
    <div class="form-field-group__header">{{ label }}</div>
    <div class="form-field-group__content"><slot /></div>
  </div>
</template>

<script>
export default {
  name: "FormFieldGroup",
  props: {
    label: { type: String, default: "" },
  },
};
</script>

<style>
.form-field-group {
  margin-bottom: 20px;
}
.form-field-group__content {
  display: flex;
  flex-wrap: wrap;
}
.form-field-group__header {
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
