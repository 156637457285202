var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.nextClick.apply(null, arguments)}}},[_c('div',{staticClass:"address-form"},[_c('form-field-group',{attrs:{"label":"Firma"}},[_c('form-field',{ref:"company",attrs:{"className":"form-field__company","placeholder":"Firmanavn (ikke påkrævet)"},model:{value:(_vm.formContent.company),callback:function ($$v) {_vm.$set(_vm.formContent, "company", $$v)},expression:"formContent.company"}})],1),_c('form-field-group',{attrs:{"label":"Ordrenummer"}},[_c('div',{staticClass:"form-field form-field__firstname",attrs:{"valid":_vm.formValid.orderNumber}},[_c('div',{staticClass:"form-field-input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formContent.orderNumber),expression:"formContent.orderNumber"}],class:{
                'is-invalid': _vm.submitted && _vm.$v.formContent.orderNumber.$error,
            },attrs:{"type":"text","placeholder":"Ordrenummer"},domProps:{"value":(_vm.formContent.orderNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formContent, "orderNumber", $event.target.value)}}}),(_vm.submitted && !_vm.$v.formContent.orderNumber.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" orderNumber is required ")]):_vm._e()])])]),_c('form-field-group',{attrs:{"label":"Navn"}},[_c('div',{staticClass:"form-field form-field__firstname",attrs:{"valid":_vm.formValid.firstname}},[_c('div',{staticClass:"form-field-input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formContent.firstname),expression:"formContent.firstname"}],class:{
                'is-invalid': _vm.submitted && _vm.$v.formContent.firstname.$error,
            },attrs:{"type":"text","placeholder":"Fornavn"},domProps:{"value":(_vm.formContent.firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formContent, "firstname", $event.target.value)}}}),(_vm.submitted && !_vm.$v.formContent.firstname.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" firstname is required ")]):_vm._e()])]),_c('div',{staticClass:"form-field form-field__lastname",attrs:{"valid":_vm.formValid.lastname}},[_c('div',{staticClass:"form-field-input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formContent.lastname),expression:"formContent.lastname"}],class:{
                'is-invalid': _vm.submitted && _vm.$v.formContent.lastname.$error,
            },attrs:{"type":"text","placeholder":"Efternavn"},domProps:{"value":(_vm.formContent.lastname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formContent, "lastname", $event.target.value)}}}),(_vm.submitted && !_vm.$v.formContent.lastname.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" lastname is required ")]):_vm._e()])])]),_c('form-field-group',{attrs:{"label":"Adresse"}},[_c('div',{staticClass:"form-field form-field__street",attrs:{"valid":_vm.formValid.street}},[_c('div',{staticClass:"form-field-input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formContent.street),expression:"formContent.street"}],class:{
                'is-invalid': _vm.submitted && _vm.$v.formContent.street.$error,
            },attrs:{"type":"text","placeholder":"Vejnavn"},domProps:{"value":(_vm.formContent.street)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formContent, "street", $event.target.value)}}}),(_vm.submitted && !_vm.$v.formContent.street.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" street is required ")]):_vm._e()])]),_c('div',{staticClass:"form-field form-field__zip",attrs:{"valid":_vm.formValid.zip}},[_c('div',{staticClass:"form-field-input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formContent.zip),expression:"formContent.zip"}],class:{
                'is-invalid': _vm.submitted && _vm.$v.formContent.zip.$error,
            },attrs:{"type":"text","placeholder":"Postnummer"},domProps:{"value":(_vm.formContent.zip)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formContent, "zip", $event.target.value)}}}),(_vm.submitted && !_vm.$v.formContent.zip.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" zip is required ")]):_vm._e()])]),_c('div',{staticClass:"form-field form-field__city",attrs:{"valid":_vm.formValid.city}},[_c('div',{staticClass:"form-field-input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formContent.city),expression:"formContent.city"}],class:{
                'is-invalid': _vm.submitted && _vm.$v.formContent.city.$error,
            },attrs:{"type":"text","placeholder":"By"},domProps:{"value":(_vm.formContent.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formContent, "city", $event.target.value)}}}),(_vm.submitted && !_vm.$v.formContent.city.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" city is required ")]):_vm._e()])])]),_c('form-field-group',{attrs:{"label":"leveringsdato"}},[_c('div',{staticClass:"form-field form-field__date",attrs:{"valid":_vm.formValid.earliestDate}},[_c('div',{staticClass:"form-field-input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formContent.earliestDate),expression:"formContent.earliestDate"}],class:{
                'is-invalid': _vm.submitted && _vm.$v.formContent.earliestDate.$error,
            },attrs:{"type":"date","placeholder":"tidligste dato*"},domProps:{"value":(_vm.formContent.earliestDate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formContent, "earliestDate", $event.target.value)}}}),(_vm.submitted && !_vm.$v.formContent.earliestDate.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" earliestDate is required ")]):_vm._e()])])]),_c('form-field-group',{attrs:{"label":"Kontaktoplysninger"}},[_c('div',{staticClass:"form-field form-field__email",attrs:{"valid":_vm.formValid.email}},[_c('div',{staticClass:"form-field-input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formContent.email),expression:"formContent.email"}],class:{
                'is-invalid': _vm.submitted && _vm.$v.formContent.email.$error,
            },attrs:{"type":"text","placeholder":"Mailadresse"},domProps:{"value":(_vm.formContent.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formContent, "email", $event.target.value)}}}),(_vm.submitted && !_vm.$v.formContent.email.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" email is required ")]):_vm._e()])]),_c('div',{staticClass:"form-field form-field__phone",attrs:{"valid":_vm.formValid.phone}},[_c('div',{staticClass:"form-field-input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formContent.phone),expression:"formContent.phone"}],class:{
                'is-invalid': _vm.submitted && _vm.$v.formContent.phone.$error,
            },attrs:{"type":"text","placeholder":"Telefonnummer"},domProps:{"value":(_vm.formContent.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formContent, "phone", $event.target.value)}}}),(_vm.submitted && !_vm.$v.formContent.phone.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" phone is required ")]):_vm._e()])])])],1),_c('btn',{attrs:{"type":"submit","label":"Næste","width":"200px","right":""}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }