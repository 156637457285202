<script>
import Btn from "@/components/Btn";
    export default {
        name: 'Modal',
        components:{
            Btn
        },
        data(){
            return{
                selectedtime:'08:00 - 12:00'
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            submitTime(){
                this.$emit("submitTime", this.selectedtime);
            }
        },
    };
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal-default"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="modal-header"
          id="modalTitle"
        >
            <div class="modelheader">
                <slot name="header">
                    <span>4 timers interval</span>
                </slot>

                <button
                    type="button"
                    class="btn-close-1"
                    @click="close"
                    aria-label="Close modal"
                >
                    x
                </button>
            </div>
        </header>

        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body">
            <div class="form-field form-field__time">
                <div class="form-field-input-wrapper">
                    <select class="model-custom-select" v-model="selectedtime">
                        <option>08:00 - 12:00</option>
                        <option>09:00 - 13:00</option>
                        <option>10:00 - 14:00</option>
                        <option>11:00 - 15:00</option>
                        <option>12:00 - 16:00</option>
                        <option>13:00 - 17:00</option>
                        <option>14:00 - 18:00</option>
                        <option>15:00 - 19:00</option>
                        <option>16:00 - 20:00</option>
                        <option>17:00 - 21:00</option>
                    </select>   
                    
                </div>
            </div>
          </slot>
        </section>

        <footer class="modal-footer">
            <btn label="Submit" width="200px" right @click="submitTime" :disabled="false"/>
        </footer>
      </div>
    </div>
  </transition>
</template>

<style>
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .model-custom-select {
 
  border: 1px solid #d0cccc;
  background-color: #fff;
  color: #56bae0;
  width: 200px;
  padding: 10px 20px;
  border-radius: 6px;
 font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.10) 0px 5px 15px;
}
.btn--right{
    position: relative;
    z-index:0
}
    .modal-default {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        width: 30%;
        box-shadow: none;
        border-radius: 10px;
    }

    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }

    .modal-header {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: #56bae0;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 600;
    }

    .modal-footer {
        border-top: 1px solid #eeeeee;
        flex-direction: column;
    }

    .modal-body {
        position: relative;
        padding: 20px 10px;
    }

    .btn-close-1 {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        font-size: 20px;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        color: #56bae0;
        background: transparent;
    }

    .btn--green {
        color: white;
        font-size: 16px;
        background: #f15726;
        border-radius: 8px;
        padding: 13px 20px;
        display: flex;
        margin: 0 auto;
        border: none;
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease;
    }

    .create-container {
        padding: 30px 0;
        max-width: 812px;
        margin: 0 auto;
    }
    .form-field__time{
        width: 100%;
        /* margin-left: 20px; */
    }
    .for-next{
        margin-bottom: 12px; 
    }

    @media (max-width:820px){
         .modal-default {
            width: 50%;
        }
    }
    @media (max-width:520px){
        .modal-default {
            width: 80%;
        }
    }
</style>
