<script>
    export default {
        name: 'Modal',
        
        data(){
            return{
                selectedoption:'Vælg venligst',
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            selectedOption(event){
                this.$emit('selectedOption',event.target.value);
            }
        },
    };
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal-cal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="modal-header"
          id="modalTitle"
        >
            <div class="modelheader">
                <slot name="header">
                    <span>Udregning af volumen (M3)</span>
                </slot>

                <button
                    type="button"
                    class="btn-close-cal"
                    @click="close"
                    aria-label="Close modal"
                >
                    x
                </button>
            </div>
        </header>

        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body">
            <div class="form-field form-field__time">
                <div class="form-field-input-wrapper">
                    <p class="addtext">Højden X Længden X Bredden = Volumen (M3)</p>
                    <p class="addtext">Alle mål skal angives i meter og er udvendige mål.</p>
                </div>
            </div>
          </slot>
        </section>
      </div>
    </div>
  </transition>

</template>

<style>
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn--right{
        position: relative;
        z-index:0
    }
    .modal-cal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        width: 30%;
        box-shadow: none;
        border-radius: 10px;
        height: 200px;
    }

    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }

    .modal-header {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: #56bae0;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 600;
    }

    .modal-footer {
        border-top: 1px solid #eeeeee;
        flex-direction: column;
    }

    .modal-body {
        position: relative;
        padding: 20px 10px;
    }

    .btn-close-cal {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        font-size: 20px;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        color: #56bae0;
        background: transparent;
    }

    .btn--green {
        color: white;
        font-size: 16px;
        background: #f15726;
        border-radius: 8px;
        padding: 13px 20px;
        display: flex;
        margin: 0 auto;
        border: none;
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease;
    }

    .create-container {
        padding: 30px 0;
        max-width: 812px;
        margin: 0 auto;
    }
    .form-field__time{
        width: 100%;
        /* margin-left: 20px; */
    }
    .for-next{
        margin-bottom: 12px; 
    }
    .addtext{
        color: #000;
        padding: 5px;
    }
</style>
