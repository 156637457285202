<template>
  <div :class="{ 'expand-panel': true, 'expand-panel--expanded': expanded }">
    <div class="expand-panel__bar" @click="$emit('toggle', index)">
      <img class="expand-panel__icon" :src="iconPath" />{{ label
      }}<img
        src="images/expand-panel-chevron.svg"
        class="expand-panel__chevron"
      />
    </div>
    <div v-show="expanded" class="expand-panel__content"><slot /></div>
  </div>
</template>

<script>
export default {
  name: "ExpandPanel",
  props: {
    index: {
      type: Number,
      default: 0,
    },
    current: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    expanded() {
      return this.current === this.index;
    },
    iconPath() {
      return this.expanded
        ? "images/expand-panel-icon--active.svg"
        : "images/expand-panel-icon.svg";
    },
  },
};
</script>

<style>
.expand-panel {
  margin-top: 20px;
}
.expand-panel__bar {
  position: relative;
  background-color: #fff;
  color: #1d4962;
  padding: 12px 40px 12px 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.1s ease-out, color 0.1s ease-out;
}
.expand-panel--expanded .expand-panel__bar {
  background-color: #56bae0;
  color: #fff;
}
.expand-panel__icon {
  width: 37px;
  height: auto;
  margin-right: 10px;
}
.expand-panel__chevron {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translate(-50%, -50%);
}
.expand-panel--expanded .expand-panel__chevron {
  transform: translate(-50%, -50%) rotate(180deg);
}
.expand-panel__content {
  padding: 30px 0;
}
@media (hover: hover) {
  .expand-panel__bar:hover {
    background-color: #d0e9f3;
  }
  .expand-panel--expanded .expand-panel__bar:hover {
    background-color: #56bae0;
  }
}
</style>
